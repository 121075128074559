<template>
    <v-progress-circular v-if="fetchingSiteData || fetchingVerificationItems" class="center-center" indeterminate/>
    <v-container v-else fluid>
        <v-row>
            <v-col class="text-start">
                <v-tooltip right>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn v-bind="attrs" v-on="on" icon @click="backToVerifications">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.pages.site.backToVerifications') }}</span>
                </v-tooltip>
            </v-col>
            <v-col class="text-end" cols="auto">
                <kurcc-user-options/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                <v-row align="center" dense justify="top">
                                    <v-col cols="12">
                                        <v-card-title class="red--text text--darken-4">
                                            {{ $vuetify.lang.t('$vuetify.pages.site.generalInfo') }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.name"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.siteName')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle :initial-value.sync="verification_items.name"
                                                                   :site-id="id" field-name="name"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="name"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.email"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.email')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.email"
                                            :site-id="id" field-name="email"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="email"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.company_name_en"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.companyNameEn')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.company_name_en"
                                            :site-id="id" field-name="company_name_en"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="company_name_en"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.company_name_ar"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.companyNameAr')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.company_name_ar"
                                            :site-id="id" field-name="company_name_ar"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="company_name_ar"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <kurcc-autocomplete
                                            :items="categories"
                                            :loading="fetchingCategories || fetchingSiteData"
                                            :multiple="false"
                                            :name="$vuetify.lang.t('$vuetify.pages.site.category')"
                                            :selected-items.sync="siteData.category"
                                            hide-details
                                            outlined
                                            readonly>
                                        </kurcc-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.category_id"
                                            :site-id="id" field-name="category_id"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="category_id"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <kurcc-image-input :image.sync="siteData.logo"
                                                           :label="$vuetify.lang.t('$vuetify.pages.site.logo')"
                                                           :loading="fetchingSiteData" :name="$vuetify.lang.t('$vuetify.pages.site.logo')"
                                                           hide-details/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle :initial-value.sync="verification_items.logo"
                                                                   :site-id="id" field-name="logo"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="logo"/>
                                    <v-col v-if="siteData.logo" cols="auto">
                                        <kurcc-image-preview :src="siteData.logo" alt="site logo"/>
                                    </v-col>
                                    <v-col class="mt-8" cols="6">
                                        <kurcc-image-input :image.sync="siteData.cover"
                                                           :label="$vuetify.lang.t('$vuetify.pages.site.cover')"
                                                           :loading="fetchingSiteData" :name="$vuetify.lang.t('$vuetify.pages.site.cover')"
                                                           hide-details/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle :initial-value.sync="verification_items.cover"
                                                                   :site-id="id" field-name="cover"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="cover"/>
                                    <v-col v-if="siteData.cover" cols="auto">
                                        <kurcc-image-preview :src="siteData.cover" alt="site cover"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                <v-row align="center" dense justify="top">
                                    <v-col cols="12">
                                        <v-card-title class="red--text text--darken-4">
                                            {{ $vuetify.lang.t('$vuetify.pages.site.commercialLicense') }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.commercial_license_number"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.licenseNumber')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.commercial_license_number"
                                            :site-id="id" field-name="commercial_license_number"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="commercial_license_number"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <kurcc-date-picker :date.sync="siteData.commercial_license_expire_date"
                                                           :label="$vuetify.lang.t('$vuetify.pages.site.licenseExpireDate')"
                                                           name="Commercial License Expire Date"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.commercial_license_expire_date"
                                            :site-id="id" field-name="commercial_license_expire_date"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="commercial_license_expire_date"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <kurcc-image-input :image.sync="siteData.commercial_license_photo"
                                                           :label="$vuetify.lang.t('$vuetify.pages.site.licensePhoto')"
                                                           :loading="fetchingSiteData" :name="`${$vuetify.lang.t('$vuetify.pages.site.commercialLicense')} ${$vuetify.lang.t('$vuetify.pages.site.licensePhoto')}`"
                                                           hide-details/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.commercial_license_photo"
                                            :site-id="id" field-name="commercial_license_photo"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="commercial_license_photo"/>
                                    <v-col v-if="siteData.commercial_license_photo" cols="auto">
                                        <kurcc-image-preview :src="siteData.commercial_license_photo"
                                                             alt="commercial license"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                <v-row align="center" dense justify="top">
                                    <v-col cols="12">
                                        <v-card-title class="red--text text--darken-4">
                                            {{ $vuetify.lang.t('$vuetify.pages.site.seniorStaff') }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.senior_staff_position"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.position')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.senior_staff_position"
                                            :site-id="id" field-name="senior_staff_position"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="senior_staff_position"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.senior_staff_name"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.name')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.senior_staff_name"
                                            :site-id="id" field-name="senior_staff_name"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="senior_staff_name"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.senior_staff_email"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.email')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.senior_staff_email"
                                            :site-id="id" field-name="senior_staff_email"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="senior_staff_email"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.senior_staff_phone_number"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.phoneNumber')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.senior_staff_phone_number"
                                            :site-id="id" field-name="senior_staff_phone_number"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="senior_staff_phone_number"/>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                <v-row align="center" dense justify="top">
                                    <v-col cols="12">
                                        <v-card-title class="red--text text--darken-4">
                                            {{ $vuetify.lang.t('$vuetify.pages.site.paci') }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.paci_license_number"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.paciNumber')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.paci_license_number"
                                            :site-id="id" field-name="paci_license_number"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="paci_license_number"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <kurcc-date-picker :date.sync="siteData.paci_license_expire_date"
                                                           :label="$vuetify.lang.t('$vuetify.pages.site.licenseExpireDate')"
                                                           name="PACI License Expire Date"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.paci_license_expire_date"
                                            :site-id="id" field-name="paci_license_expire_date"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="paci_license_expire_date"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <kurcc-image-input :image.sync="siteData.paci_license_photo"
                                                           :label="$vuetify.lang.t('$vuetify.pages.site.licensePhoto')"
                                                           :loading="fetchingSiteData" :name="`${$vuetify.lang.t('$vuetify.pages.site.paci')} ${$vuetify.lang.t('$vuetify.pages.site.licensePhoto')}`"
                                                           hide-details/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.paci_license_photo"
                                            :site-id="id" field-name="paci_license_photo"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="paci_license_photo"/>
                                    <v-col v-if="siteData.paci_license_photo" cols="auto">
                                        <kurcc-image-preview :src="siteData.paci_license_photo" alt="paci license"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                <v-row align="center" dense justify="top">
                                    <v-col cols="12">
                                        <v-card-title class="red--text text--darken-4">
                                            {{ $vuetify.lang.t('$vuetify.pages.site.municipality') }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-date-picker
                                            :date.sync="siteData.municipality_license_expire_date"
                                            :label="$vuetify.lang.t('$vuetify.pages.site.licenseExpireDate')"
                                            name="Municipality License Expire Date"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.municipality_license_expire_date"
                                            :site-id="id" field-name="municipality_license_expire_date"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="municipality_license_expire_date"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <kurcc-image-input :image.sync="siteData.municipality_license_photo"
                                                           :label="$vuetify.lang.t('$vuetify.pages.site.licensePhoto')"
                                                           :loading="fetchingSiteData" :name="`${$vuetify.lang.t('$vuetify.pages.site.municipality')} ${$vuetify.lang.t('$vuetify.pages.site.licensePhoto')}`"
                                                           hide-details/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.municipality_license_photo"
                                            :site-id="id" field-name="municipality_license_photo"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="municipality_license_photo"/>
                                    <v-col v-if="siteData.municipality_license_photo" cols="auto">
                                        <kurcc-image-preview :src="siteData.municipality_license_photo"
                                                             alt="municipality license"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-text>
                                <v-row align="center" dense justify="top">
                                    <v-col cols="12">
                                        <v-card-title class="red--text text--darken-4">
                                            {{ $vuetify.lang.t('$vuetify.pages.site.managingPartner') }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.managing_partner_name"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.name')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.managing_partner_name"
                                            :site-id="id" field-name="managing_partner_name"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="managing_partner_name"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.managing_partner_email"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.email')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.managing_partner_email"
                                            :site-id="id" field-name="managing_partner_email"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="managing_partner_email"/>
                                    <v-col cols="6"/>
                                    <v-col cols="6">
                                        <v-text-field v-model="siteData.managing_partner_phone_number"
                                                      :label="$vuetify.lang.t('$vuetify.pages.site.phoneNumber')"
                                                      :loading="fetchingSiteData"
                                                      hide-details outlined readonly/>
                                    </v-col>
                                    <v-col cols="6">
                                        <kurcc-verification-toggle
                                            :initial-value.sync="verification_items.managing_partner_phone_number"
                                            :site-id="id" field-name="managing_partner_phone_number"/>
                                    </v-col>
                                    <kurcc-rejection-reason
                                        :id="id"
                                        :loading="fetchingVerificationItems"
                                        :verification-items="verification_items"
                                        field-name="managing_partner_phone_number"/>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Site from "@/modules/sites/models/site";

export default {
    name: "KurccSiteVerificationPage",
    components: {
        KurccUserOptions: () => import('@/modules/app/components/KurccUserOptions'),
        KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete'),
        KurccDatePicker: () => import('@/modules/app/components/KurccDatePicker'),
        KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
        KurccImagePreview: () => import('@/modules/app/components/KurccImagePreview'),
        KurccVerificationToggle: () => import('@/modules/verifications/components/KurccVerificationToggle'),
        KurccRejectionReason: () => import('@/modules/verifications/components/KurccRejectionReason')
    },
    props: ['site', 'id'],
    data() {
        return {
            siteData: new Site(),
            categories: [],
            verification_items: null,
            fetchingCategories: false,
            fetchingVerificationItems: false,
            fetchingSiteData: false,
            loading: false
        }
    },
    methods: {
        fetchSiteData() {
            this.fetchingSiteData = true
            this.$store.dispatch('getSite', {id: this.id})
                .then(res => {
                    this.siteData = res
                }).finally(() => {
                this.fetchingSiteData = false
            })
        },
        getAllCategories() {
            this.fetchingCategories = true
            this.$store.dispatch('getAllCategories', {withIncludes: false})
                .then(res => {
                    this.categories = res.items
                }).finally(() => {
                    this.fetchingCategories = false
                }
            )
        },
        backToVerifications() {
            this.$router.push({name: 'verifications'})
        },
        getVerificationItems() {
            this.fetchingVerificationItems = true
            this.$store.dispatch('getVerificationItems', {site_id: this.id})
                .then(res => {
                    this.verification_items = res
                }).finally(() => {
                    this.fetchingVerificationItems = false
                }
            )
        }
    },
    created() {
        if (this.site) {
            this.siteData = this.$lodash.cloneDeep(this.site)
        } else this.fetchSiteData()

        this.getAllCategories()
        this.getVerificationItems()
    }
}
</script>

<style lang="scss" scoped>
.w-100 {
    width: 100%;
}
</style>
